import React from 'react'
import styled from '@emotion/styled'
import './auth.css'
import {css} from '@emotion/react'
import {Link} from 'gatsby'
import {signupRequest} from '../api'
import {Alert} from 'reactstrap'

export default class SignupPage extends React.Component {
  state = {
    username: '',
    email: '',
    password1: '',
    password2: '',
    messages: [],
  }

  handleInputChange = (event) => {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		// const error = this.validateInput(name, value)

		this.setState({
			[name]: value,
			// [`${name}Error`]: error,
		})
  }

  handleFormSubmit = async (event) => {
    event.preventDefault()
    const result = await signupRequest(this.state)
    const messages = []
    if (result.hasOwnProperty('key')){
      localStorage.setItem('key', JSON.stringify(result['key']))
      messages.push(
        <Alert color="success">
          Succès ! Votre compte a été créé. Vous allez être redirigés dans un instant.
        </Alert>
      )
    } else {
      for(let key in result){
        for (let msg in result[key]){
          messages.push(
          <Alert color="danger">
            {result[key][msg]}
          </Alert>
          )
        }
      }
    }
    this.setState({messages: messages})
  }

  render(){
    const messages = this.state.messages
    return (
      <Container>
        <LoginForm>
          <form css={form} onSubmit={this.handleFormSubmit}>

          <Title>Créer un compte</Title>

          {messages}
          
          {/* <Separator><I>ou</I></Separator> */}
          <div className="form-group">
            <input type="text" className="form-control" name="username" placeholder="Nom" onChange={this.handleInputChange} value={this.username} required="required"/>		
          </div>
          <div className="form-group">
            <input type="text" className="form-control" name="email" placeholder="Email" onChange={this.handleInputChange} value={this.username} required="required"/>		
          </div>
          <div className="form-group">
            <input type="password" className="form-control" name="password1" placeholder="Mot de passe" onChange={this.handleInputChange} value={this.username} required="required"/>	
          </div>        
          <div className="form-group">
            <input type="password" className="form-control" name="password2" placeholder="Confirmer mot de passe" onChange={this.handleInputChange} value={this.username} required="required"/>	
          </div>        
          <div className="form-group mb-2">
              <Button type="submit" className="btn btn-primary btn-lg">Sign in</Button>
          </div>
          <br />
          </form>
          <p className="text-center small text-body">Déjà inscrit ? <Link to="/login" className="text-white">connexion</Link></p>
        </LoginForm>
      </Container>
    )
  }
}

const Container = styled.div`
  color: #fff;
  background: #346bc2;
  height: 1000px;
`
const LoginForm = styled.div`
  width: 310px;
  margin: 0 auto;
  padding: 100px 0 30px;
`

const form = css({
  color: '#999',
  borderRadius: 10,
  marginBottom: 15,
  background: '#fff',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
  padding: 30,
  // position: 'relative',
})

const Title = styled.h2`
  font-size: 24px;
  text-align: center;
  color: #454959;
  margin: 10px 0 25px;
`
const Separator = styled.div`
  height: 0;
  margin: 0 auto 20px;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  width: 30%;
`
const I = styled.i`
    padding: 0 10px;
		font-size: 15px;
		text-align: center;
		background: #fff;
		display: inline-block;
		position: relative;
		top: -13px;
		z-index: 1;
`
const Button = styled.button`
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background: #99c432;		
  margin-bottom: 20px;
  float: left;
  margin: 1px 0 20px 0;
  width: 100%;
  min-width: 20px;
  font-size: 18px;
  background-color: #2557b7;
  &:hover{
    background: #346bc2;
  }
`
const SocialButtons = styled.div`

`
const Facebook = styled.a`
  background: #507cc0;
  border: none;
  &:hover{
    background: #507cc0;
    opacity: 0.8;
  }
`
const Twitter = styled.a`
  background: #64ccf1;
  border: none;
  &:hover{
    background: #64ccf1;
    opacity: 0.8;
  }
`


// background-image: linear-gradient(0deg,rgba(0,0,0,0) 50%,rgba(0,0,0,.5) 100%),radial-gradient(50% 120%,rgba(0,0,0,0) 50%,rgba(0,0,0,.7) 100%),url("https://assets.nflxext.com/ffe/siteui/vlv3/cf96bcd2-09b3-4fd2-966e-89cfae0019ed/9f688eb8-e917-46a7-9876-d59ad4a3f46f/FR-fr-20190121-popsignuptwoweeks-perspective_alpha_website_small.jpg");